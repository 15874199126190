import styled from '@emotion/styled';
import { FooterWrapper } from '@zola/zola-ui/src/components/PreAuthV3/PreauthFooterV3.styles';
import { COLORS3, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';
import { VERTICAL_NAV_WIDTH } from 'hooks/useIsPostAuthNav3Active';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const InnerContainer = styled.div<{
  isPostAuthNav3?: boolean;
}>`
  width: 100%;
  max-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${MEDIA_QUERY.DESKTOP} {
    width: ${({ isPostAuthNav3 }) =>
      isPostAuthNav3 ? `calc(100% - ${VERTICAL_NAV_WIDTH}px)` : '100%'};
    margin-left: ${({ isPostAuthNav3 }) => (isPostAuthNav3 ? `${VERTICAL_NAV_WIDTH}px` : 'auto')};
  }
  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    border-left: ${({ isPostAuthNav3 }) =>
      isPostAuthNav3 ? null : `1px solid ${COLORS3.BLACK_030}`};
    border-right: ${({ isPostAuthNav3 }) =>
      isPostAuthNav3 ? null : `1px solid ${COLORS3.BLACK_030}`};
  }
  ${FooterWrapper} {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: stretch;
`;
